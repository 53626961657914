.portfolio_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio_items {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio_items:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio_items img {
  border-radius: 1.5rem;
  overflow: hidden;
}
.portfolio_items h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio_items_cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICE) ================= */
@media screen and (max-width: 1024px) {
  .portfolio_container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}
/* ============= MEDIA QUERIES (SMALL DEVICE) ================= */
@media screen and (max-width: 600px) {
  .portfolio_container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}